import Vue from 'vue'
import VueRouter  from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/our-services',
    name: 'OurServicesView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OurServicesView.vue')
  },
  {
    path: '/physical-security-consulting',
    name: 'PhysicalSecurityConsultingView',
    component: () => import('../views/PhysicalSecurityConsultingView.vue')
  },
  {
    path: '/supply-chain-security',
    name: 'SupplyChainSecurityView',
    component: () => import('../views/SupplyChainSecurityView.vue')
  },
  {
    path: '/adhoc-security-services',
    name: 'AdhocSecurityServicesView',
    component: () => import('../views/AdhocSecurityServicesView.vue')
  },
  {
    path: '/supply-chain-management',
    name: 'SupplyChainManagementView',
    component: () => import('../views/SupplyChainManagementView.vue')
  },
  {
    path: '/camp-and-life-support-services',
    name: 'CampAndLifeSupportManagementView',
    component: () => import('../views/CampAndLifeSupportServicesView.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUsView',
    component: () => import('../views/ContactUsView.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
