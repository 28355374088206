<template>
  <div>
    <b-carousel
      id="carousel"
      v-model="slide"
      :interval="6000"
      background="#ababab"
      img-width="1920"
      img-height="600"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      no-hover-pause
      fade
      content-visible-up
      class="d-lg-block d-none"
    >
      <!-- Text slides with image -->
      <b-carousel-slide :img-src="require('@/assets/carousel/slider-1.jpg')"></b-carousel-slide>

      <!-- Slides with custom text -->
      <b-carousel-slide :img-src="require('@/assets/carousel/slider-2.jpg')"></b-carousel-slide>

      <!-- Slides with image only -->
      <b-carousel-slide :img-src="require('@/assets/carousel/slider-3.jpg')"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'CarouselComponent',

  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>