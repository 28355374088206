<template>
    <footer class="bg-grey-left">
        <b-container fluid class="pt-4">
            <b-row>
                <b-col lg="6" md="12" class="text-white">
                    <h2>Contact Us</h2>

                    <h6>Operations and Services</h6>
                    
                    Email: <b-link href="mailto:info@africarisk.co.za" id="linkEmailFooter">info@africarisk.co.za</b-link>
                </b-col>

                <b-col lg="6" md="12" class="text-white">
                    <h2>Director</h2>

                    <h6>Lyle Simes – CSMP® Dip</h6>
                    
                    Email: <b-link href="mailto:ls@africarisk.co.za" id="linkEmailFooter">ls@africarisk.co.za</b-link>
                </b-col>
            </b-row>
        </b-container>

        <hr/>

        <b-container class="text-white text-center pb-2">
             &copy; 2022 - Africa Risk Consulting
        </b-container>
    </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>

<style scoped>
 a {
     color: #fff;
     text-decoration: none;
 }
 a:hover, a:focus {
     color: #bf182a;
 }
</style>