<template>
  <div id="app">
    <NavigationComponent></NavigationComponent>
    <router-view class="main-padding" />
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import NavigationComponent from '@/components/NavigationComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'HomeView',
  components: {
    NavigationComponent, FooterComponent
  },
}
</script>
<style>
#app {
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

nav a.router-link-exact-active, nav a:hover,nav a:focus  {
  color: #bf182a;
  text-decoration: none;
}

#logo {
  width: 100px;
}

nav {
    z-index: 100000;
}

div.tooltip {
    z-index: 100000;
}

.bg-grey-left {
    background: #8d8d8d;
}

.bg-grey-right {
    background: #474747;
}

.main-padding {
    padding-top: 118px;
}

button.btn-primary {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
button.btn-primary:hover, button.btn-primary:focus {
    color: #fff;
    background-color: #c11729;
    border-color: #c11729;
}
</style>
