<template>
    <b-navbar toggleable="lg" type="dark" variant="dark" class="position-fixed w-100">
      <b-navbar-brand href="/">
        <b-img :src="require('../assets/logo.png')" fluid alt="Africa Risk Consulting" title="Africa Risk Consulting"
          id="logo"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
           <b-nav-item active v-for="i, index in links" :key="index">
            <router-link :to="i.linkTo">{{i.label}}</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>

      <b-link href="mailto:info@africarisk.co.za" id="linkEmail" class="d-lg-block d-none">info@africarisk.co.za</b-link>
      <b-tooltip target="linkEmail" triggers="hover">
        Email Us
      </b-tooltip>
    </b-navbar>
</template>

<script>
export default {
  name: 'NavigationComponent',
  data(){
      return{
          links: [
              { linkTo: '/', label: "Home" },
              { linkTo: '/our-services', label: "Services" },
              { linkTo: '/physical-security-consulting', label: "Security Consulting" },
              { linkTo: '/adhoc-security-services', label: "ADHOC Services" },
              { linkTo: '/supply-chain-security', label: "Supply Chain Security" },
              { linkTo: '/supply-chain-management', label: "Supply Chain Management" },
              { linkTo: '/camp-and-life-support-services', label: "Life Support" },
              { linkTo: '/contact-us', label: "Contact Us" },
          ],
      }
  }
}
</script>