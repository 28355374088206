<template>
    <b-card no-body :img-src="img" :img-alt="header" img-top>
        <template #header>
            <h6 class="mb-0">{{header}}</h6>
        </template>
    </b-card>
</template>

<script>
export default {
  name: 'HomeCardComponent',
  props: {
    img: String,
    header: String,
  },
}
</script>