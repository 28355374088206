<template>
  <div>
    <CarouselComponent></CarouselComponent>

    <b-container fluid class="bg-grey-right">
      <b-row class="d-lg-block d-none position-absolute row text-white" id="header-row">
        <h1 class="d-none">AFRICA RISK CONSULTING</h1>
        <h1>
          <span class="text-danger">A</span>FRICA <span class="text-danger">R</span>ISK <span
            class="text-danger">C</span>ONSULTING
        </h1>

        <h2 class="text-danger">(ARC)</h2>

        <h3>Registration Number: 2022/523611/07 </h3>

        <h4>“Success without integrity is failure”</h4>
      </b-row>

      <b-row class="d-lg-none row text-white">
        <h1 class="d-none">AFRICA RISK CONSULTING</h1>
        <h1>
          <span class="text-danger">A</span>FRICA <span class="text-danger">R</span>ISK <span
            class="text-danger">C</span>ONSULTING
        </h1>

        <h2 class="text-danger">(ARC)</h2>

        <h3>Registration Number: 2022/523611/07 </h3>

        <h4>“Success without integrity is failure”</h4>
      </b-row>

      <b-row align-v="center" class="py-5">
        <b-col cols="12" class="bg-grey-right text-white px-5">
          <h2 class="pb-4">About Us</h2>

          <p>
            Africa Risk Consulting specialize in identifying threats and
            vulnerabilities and create countermeasures to reduce the risk. ARC
            generate customized physical security designs and plans that are
            tailored to your organization’s security requirements.
          </p>

          <p>
            Africa Risk Consulting are completely independent from any
            manufacturer, integrator, supplier, vendor, or reseller of physical
            security systems or equipment. We’re unbiased, and we give the
            best advice available to our clients based on their needs. Our prime
            objective is to offer physical security and consulting services with
            guaranteed impartiality in our findings, analyses, conclusions, and
            recommendations.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="p-4 bg-dark">
      <b-row>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/our-services" class="text-decoration-none">
            <HomeCardComponent header="Our Services" :img="require('@/assets/card-services.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/physical-security-consulting" class="text-decoration-none">
            <HomeCardComponent header="Physical Security Consulting"
              :img="require('@/assets/card-physical-security.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/adhoc-security-services" class="text-decoration-none">
            <HomeCardComponent header="ADHOC Security Services"
              :img="require('@/assets/card-adhoc-security-services.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/supply-chain-security" class="text-decoration-none">
            <HomeCardComponent header="Supply Chain Security"
              :img="require('@/assets/card-supply-chain-security.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/supply-chain-management" class="text-decoration-none">
            <HomeCardComponent header="Supply Chain Management"
              :img="require('@/assets/card-supply-chain-management.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
        <b-col lg="2" md="12" class="pb-4 pb-lg-0">
          <router-link to="/camp-and-life-support-services" class="text-decoration-none">
            <HomeCardComponent header="Life Support"
              :img="require('@/assets/card-camp-and-life-support.jpg')"></HomeCardComponent>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CarouselComponent from '../components/CarouselComponent.vue'
import HomeCardComponent from '../components/HomeCardComponent.vue'

export default {
  name: 'HomeView',
  components: {
    CarouselComponent, HomeCardComponent
  },
}
</script>

<style scoped>
#header-row {
  top: 30%;
  z-index: 10000;
  right: 25%;
  background-color: rgb(0 0 0 / 30%);
  width: 50%;
}

h1, .h1 {
    font-size: 4.5rem;
}
a {
    color: #212529;
}

a:hover, a:focus {
    color: #c0192b;
}
</style>